import React, { useEffect, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { getScenario } from 'src/api/scenario';
import { ProjectionVariant, QKeys } from 'src/api/types';
import { GradientText, ModifierModalSkeleton, Spacer } from 'src/common';
import Chart from 'src/components/charts/Chart';
import ChartBlank from 'src/components/charts/ChartBlank';
import { LogSwitch } from 'src/components/charts/comps';
import { CordData } from 'src/components/charts/util';
import { getScenarioControls } from 'src/api/plan';
import { ViewContainer } from '../ViewContainer';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useVersion from 'src/hooks/useVersion';
import { LegendComposed, TableForm } from './comps';
import { useStateSelector } from 'src/redux';

interface ScenarioViewProps {
  scenarioId: string;
  onClose: () => void;
}

const ScenarioView: React.FC<ScenarioViewProps> = ({ scenarioId, onClose }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [completeChart, setCompleteChart] = useState<CordData[]>([]);
  const [liveChart, setLiveChart] = useState<CordData[]>([]);
  const { version, VHeader } = useVersion();
  const isMd = useBreakpoints('md');

  useEffect(() => {
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId, variant: ProjectionVariant.live, version }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId, variant: ProjectionVariant.base, version }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId, variant: ProjectionVariant.complete, version }]);
    queryCache.invalidateQueries([QKeys.ScenarioControls, scenarioId]);
  }, [scenarioId, version]);

  const { data: scenarioLive, isLoading: isLiveLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.live, version }],
    getScenario,
  );

  const { data: scenarioBase, isLoading: isBaseLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.base, version }],
    getScenario,
  );
  const { isLoading: isCompleteLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.complete, version }],
    getScenario,
    {
      onSuccess: (data) => {
        setCompleteChart(data.chart || []);
      },
    },
  );
  const { data: planScenarios, isLoading: isPlanLoading } = useQuery(
    [QKeys.ScenarioControls, scenarioId, { version }],
    () => getScenarioControls(scenarioId, { version }),
  );

  const isValidScenario = scenarioLive?.expected_loss != null;
  const isLoading = isLiveLoading || isCompleteLoading || isBaseLoading || isPlanLoading;

  const showAllProjected = !!scenarioBase?.implementationPlans
    ?.filter((plan) => plan.control && plan.control.status !== 'Abandoned')
    ?.flatMap((plan) => plan.modifiers)
    ?.filter((modifier) => modifier.type !== 'NOTE_TYPE')?.length;

  return (
    <ViewContainer>
      <div className={!!completeChart.length ? '' : 'closed'}>
        <div className="sw-top">
          <GradientText>LOSS EXCEEDANCE CURVE</GradientText>
          <div className="sw-top__end">
            {!isMd && <LegendComposed />}
            <LogSwitch skey="log:pr_scenario" isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
          </div>
        </div>
        {isMd && (
          <>
            <Spacer $px={10} />
            <LegendComposed />
          </>
        )}
        {VHeader && (
          <>
            <Spacer $px={10} />
            {VHeader}
          </>
        )}
        <Spacer $px={20} />
        {isValidScenario && !isLoading && !!completeChart.length ? (
          <Chart
            isLogarithmic={isLogarithmic}
            // @ts-ignore
            input={[
              showAllProjected && {
                data: scenarioBase?.chart || [],
                upper: scenarioBase?.upper || 1,
                lower: scenarioBase?.lower || 1,
                name: 'Unmodified Risk',
                color: colors.prussianBlue,
                fillColor: 'rgba(2, 3, 51, 0.07)',
                legendId: 'leg-base-pv',
              },
              {
                data: liveChart.length > 0 ? liveChart : scenarioLive?.chart || [],
                upper: scenarioLive?.upper || 1,
                lower: scenarioLive?.lower || 1,
                name: 'Live Risk',
                color: colors.gp2,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                legendId: 'leg-s1-pv',
              },
              showAllProjected && {
                data: completeChart || [],
                upper: scenarioLive?.upper || 1,
                lower: scenarioLive?.lower || 1,
                name: 'Projected Risk',
                color: colors.brightBlue,
                fillColor: 'rgba(100, 149, 237, 0.07)',
                legendId: 'leg-s2-pv',
              },
            ].filter(Boolean)}
          />
        ) : (
          <ChartBlank />
        )}
        <Spacer $px={40} />
        {scenarioBase && planScenarios && (
          <TableForm
            onClose={onClose}
            scenario={scenarioBase}
            setLiveChart={setLiveChart}
            setCompleteChart={setCompleteChart}
            planScenarios={planScenarios}
            isInModal={true}
          />
        )}
      </div>
      {!completeChart.length && <ModifierModalSkeleton />}
    </ViewContainer>
  );
};

export default ScenarioView;
