import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0 10px 30px;
  border-radius: 16px;
  position: relative;
  max-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 0px 18px ${({ theme }) => theme.colors.shadowLight});
  height: 100%;
  &:hover {
    z-index: 1;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    height: 50px;
    &__left {
      display: flex;
      flex-direction: column;
    }
    &__right {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 15px 0 15px 20px;
    .controls {
      padding-right: 15px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0 10px 15px;
    .controls {
      padding-right: 10px;
    }
  }

  .container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 70px);
    padding: 10px 0;
    &___upcoming {
      display: flex;
      flex-direction: column;
      grid-gap: 22px;

      &__head {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        cursor: pointer;
        user-select: none;
      }
      &__collapse {
        overflow: hidden;
      }

      &__item {
        display: flex;
        flex-direction: column;
        grid-gap: 4px;
      }
    }
  }
`;
