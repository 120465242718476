import React, { memo, useEffect, useState } from 'react';
import { DataTitle, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { Control, Modifiers, ProjectedScenario, QKeys } from 'src/api/types';
import TreatModifier from './Modifiers/TreatModifier';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import TransferModifier from './Modifiers/TransferModifier';
import _ from 'lodash';
import { queryCache, useMutation } from 'react-query';
import { deleteModifiers, updateModifiers } from 'src/api/plan';
import { Copy, Note } from './comps';
import Button from 'src/components/form/Button';
import { useAuth } from 'src/state/auth';

const Div = styled.div`
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.cultured};
  padding: 15px 26px 30px 26px;
  position: relative;

  .error-text {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object({
  treat: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Required'),
      data: Yup.object().when(['type'], (values, schema) => {
        if (values[0] === 'TREAT_REDUCE_CONSEQUENCE') {
          return Yup.object().shape({
            lower: Yup.number().required('Required'),
            upper: Yup.number().required('Required'),
          });
        }
        if (values[0] === 'TREAT_REDUCE_FREQUENCY') {
          return Yup.object().shape({
            value: Yup.number().required('Required').min(0, 'Must be greater than 0'),
          });
        }

        return schema;
      }),
    }),
  ),
  transfer: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Required'),
      data: Yup.object().when(['type'], (values, schema) => {
        if (values[0] === 'TRANSFER_LIMIT') {
          return Yup.object().shape({
            excess: Yup.number().required('Required').min(0, 'Must be greater than 0'),
            tpc: Yup.number()
              .required('Required')
              .min(Yup.ref('excess'), 'Total Policy Cover must be greater than or equal to Excess'),
          });
        }

        return schema;
      }),
    }),
  ),
  notes: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required().equals(['NOTE_TYPE']),
      data: Yup.object().shape({
        note: Yup.string().nullable(),
      }),
    }),
  ),
});

const setDefaultIsPercentage = (treat: any) => {
  if (treat.type === 'TREAT_REDUCE_CONSEQUENCE' || treat.type === 'TREAT_REDUCE_FREQUENCY') {
    return {
      ...treat,
      data: {
        ...treat.data,
        isPercentage: treat.data?.isPercentage ?? false,
      },
    };
  }
  return treat;
};

interface ModifierProps {
  projectedScenario: ProjectedScenario;
  control: Control;
  useCallback?: (state: { values: Modifiers; errors: FormikErrors<Modifiers>; isValid: boolean }) => void;
  disabled?: boolean;
  isDirtyOutside?: boolean;
  onSave?: () => Promise<void>;
  addSubmitHandler?: (handler: () => void) => void;
  isInModal?: boolean;
  noResult?: boolean;
  withCopy?: boolean;
}

export const Modifier: React.FC<ModifierProps> = memo(
  ({
    projectedScenario,
    control,
    useCallback,
    disabled,
    isDirtyOutside,
    onSave,
    addSubmitHandler,
    isInModal,
    noResult,
    withCopy,
  }) => {
    const [err, setErr] = useState('');
    const { userFeatures } = useAuth();

    const [update] = useMutation(updateModifiers, {
      onError: (err: any) => setErr(err.message),
      onSuccess: async () => {
        await queryCache.invalidateQueries([QKeys.ControlScenarios, control.id]);
        await queryCache.invalidateQueries([QKeys.ScenarioControls, projectedScenario.id]);
        await queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: projectedScenario.id, variant: 'complete' }]);
        await queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: projectedScenario.id }]);

        await queryCache.refetchQueries([QKeys.ControlScenarios, control.id]);
        await queryCache.refetchQueries([QKeys.ScenarioControls, projectedScenario.id]);
      },
    });
    const [remove] = useMutation(deleteModifiers, { onError: (err: any) => setErr(err.message) });

    const formik = useFormik({
      validationSchema,
      initialValues:
        control.modifiers ||
        ({
          treat: [],
          transfer: [],
          notes: [
            {
              type: 'NOTE_TYPE',
              data: {
                note: null,
              },
              isLive: false,
            },
          ],
        } as Modifiers),
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        const updatedValues = _.cloneDeep(values);
        updatedValues.treat = updatedValues.treat.map(setDefaultIsPercentage);

        setErr('');
        const delIds = [
          ..._.differenceBy(control.modifiers?.treat || [], updatedValues.treat, 'id'),
          ..._.differenceBy(control.modifiers?.transfer || [], updatedValues.transfer, 'id'),
          ..._.differenceBy(control.modifiers?.notes || [], updatedValues.notes, 'id'),
        ].map((el) => {
          return { id: el.id };
        }) as { id: string }[];

        await update({
          control_id: control.id,
          scenario_id: projectedScenario.id,
          modifiers: updatedValues,
        });

        if (delIds.length) {
          await remove({
            scenario_id: projectedScenario.id,
            control_id: control.id,
            modifiers: delIds,
          });
        }

        await queryCache.invalidateQueries([QKeys.ControlScenarios, control.id]);
        await queryCache.invalidateQueries([QKeys.ScenarioControls, projectedScenario.id]);
        if (onSave) {
          await onSave();
        }

        return;
      },
    });

    const { values, errors, isValid, touched, isSubmitting, dirty, handleSubmit } = formik;

    useEffect(() => {
      if (useCallback && !isSubmitting) {
        useCallback({ values, errors, isValid });
      }
    }, [useCallback, values, errors, isValid, touched, isSubmitting]);

    useEffect(() => {
      if (addSubmitHandler) {
        addSubmitHandler(handleSubmit);
      }
    }, [addSubmitHandler]);

    const handlePaste = (modifiers: Modifiers) => {
      const existingTreatIds = new Set(values.treat.map((t) => t.id));
      const existingTransferIds = new Set(values.transfer.map((t) => t.id));
      const existingNotesIds = new Set(values.notes.map((t) => t.id));

      formik.setFieldValue(
        'treat',
        modifiers.treat.map((el) => ({
          ...el,
          id: existingTreatIds.has(el.id) ? el.id : undefined,
        })),
      );
      formik.setFieldValue(
        'transfer',
        modifiers.transfer.map((el) => ({
          ...el,
          id: existingTransferIds.has(el.id) ? el.id : undefined,
        })),
      );
      formik.setFieldValue(
        'notes',
        modifiers.notes.map((el) => ({
          ...el,
          id: existingNotesIds.has(el.id) ? el.id : undefined,
        })),
      );
    };

    return (
      <Div>
        <DataTitle weight={600} css="user-select: none;">
          MODIFIERS
        </DataTitle>
        {userFeatures.app.modifier.copy && withCopy && (
          <Copy modifiers={values} onPaste={handlePaste} strategy={control.strategy} isValid={isValid} />
        )}
        <Spacer $px={16} />
        <FormikProvider value={formik}>
          {control.strategy === 'Treat' && (
            <TreatModifier
              projectedScenario={projectedScenario}
              disabled={disabled}
              isDirtyOutside={isDirtyOutside}
              isInModal={isInModal}
              noResult={noResult}
            />
          )}
          {control.strategy === 'Transfer' && (
            <TransferModifier
              projectedScenario={projectedScenario}
              disabled={disabled}
              isDirtyOutside={isDirtyOutside}
              isInModal={isInModal}
              noResult={noResult}
            />
          )}
          <Note idx={0} namePrefix="notes" disabled={disabled} />

          {!disabled && dirty && (
            <ButtonContainer>
              <Button onClick={() => handleSubmit()} primary type="submit" disabled={isSubmitting || !isValid}>
                Save
              </Button>
              <Button onClick={() => formik.resetForm()} secondary disabled={isSubmitting}>
                Cancel
              </Button>
            </ButtonContainer>
          )}

          {err && (
            <>
              <Spacer $px={15} />
              <Poppins px={14} color="error">
                {err}
              </Poppins>
            </>
          )}
        </FormikProvider>
      </Div>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);
