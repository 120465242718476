import { Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfScenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { FooterStartComponent, Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { formatDate, isUndefined, numeralFormat } from 'src/utils/misc';
import { LegendExceedance } from './legends';
import { ControlsTable } from './ControlsTable';
import { IncidentsTable } from './IncidentsTable';
import { NoData } from './NoData';
import { EmptyChart } from './EmptyChart';
import { TitleLink } from './TitleLink';
import { VariantValuesPDF } from 'src/components/pdf/comps';
import { ColorSet } from 'src/redux/reducers/theme';

interface ScenarioPageProps {
  scenario: PdfScenario;
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  assessmentData: {
    reviewDate: string;
    id: string;
  };
  footerTitle?: string;
  colors: ColorSet;
}

export const ScenarioPage: React.FC<ScenarioPageProps> = ({
  scenario,
  onRender,
  endComponent,
  assessmentData,
  footerTitle = '',
  colors,
}) => {
  const isEmptyChart = scenario.chart?.every((el) => el.y === 1 && el.x === 0);

  return (
    <>
      <Page size={'A4'} style={pdfStyles.page}>
        <Header endComponent={endComponent} colors={colors} />

        {/* @ts-ignore */}
        <View render={(data) => onRender(data.pageNumber)} />
        <View style={pdfStyles.section}>
          <TitleLink
            title={scenario.name}
            intLinkId={scenario.name.toLowerCase()}
            extLink={`risk-assessments/${assessmentData.id}/scenario/${scenario.id}`}
            colors={colors}
          />

          <View style={pdfStyles.spacer8} />
          <View style={pdfStyles.spacer4} />
          <View style={{ flexDirection: 'column', gap: 16 }}>
            <View style={{ flexDirection: 'column', marginBottom: 16 }}>
              <Text style={pdfStyles.label}>EXPECTED LOSS</Text>
              <View style={{ flexDirection: 'row', marginTop: 8 }}>
                <View style={{ marginRight: 15 }}>
                  <VariantValuesPDF value={{ base: scenario.expected_loss }} variant="scenario" />
                </View>
                <View style={{ marginRight: 15 }}>
                  <VariantValuesPDF value={{ live: scenario.live_expected_loss }} variant="scenario" />
                </View>
                <View>
                  <VariantValuesPDF value={{ complete: scenario.projected_expected_loss }} variant="scenario" />
                </View>
              </View>
            </View>

            <View style={{ flexDirection: 'row', gap: 40 }}>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>REVIEW DATE</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{formatDate(scenario.review_date, { emptyValue: '-' })}</Text>
              </View>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>IDENTIFIED DATE</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{formatDate(scenario.risk_identified_date, { emptyValue: '-' })}</Text>
              </View>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>EDITED</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>
                  {formatDate(scenario.edited_at, { emptyValue: '-', formatType: 'datetime' })}
                </Text>
              </View>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>UPDATED</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>
                  {formatDate(scenario.updated_at, { emptyValue: '-', formatType: 'datetime' })}
                </Text>
              </View>
            </View>
          </View>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <Text style={pdfStyles.gradText}>RISK STATEMENT</Text>
            <View style={pdfStyles.spacer8} />
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 220 }}>
                <Text style={pdfStyles.label}>SOURCES</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.source) ? (
                  <Text style={pdfStyles.value}>{riskSourcesNames[scenario.source]}</Text>
                ) : (
                  <NoData colors={colors} />
                )}
              </View>
              <View>
                <Text style={pdfStyles.label}>EVENTS</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.event) ? (
                  <Text style={pdfStyles.value}>{riskEventsNames[scenario.event]}</Text>
                ) : (
                  <NoData colors={colors} />
                )}
              </View>
            </View>
            <View style={pdfStyles.spacer8} />
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 220 }}>
                <Text style={pdfStyles.label}>CONSEQUENCES</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.consequence) ? (
                  <Text style={pdfStyles.value}>{riskConsequencesNames[scenario.consequence]}</Text>
                ) : (
                  <NoData colors={colors} />
                )}
              </View>
              <View>
                <Text style={pdfStyles.label}>RISK OWNER</Text>
                <View style={pdfStyles.spacer1} />
                {scenario.risk_owner?.text ? (
                  <Text style={pdfStyles.value}>{scenario.risk_owner.text}</Text>
                ) : (
                  <NoData colors={colors} />
                )}
              </View>
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <Text style={pdfStyles.gradText}>LOSS EXCEEDANCE CURVE</Text>
            <View style={pdfStyles.spacer4} />
            {!isEmptyChart ? (
              <>
                <Image src={scenario.chartImage} style={pdfStyles.chartImage} />
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 12, marginTop: -8 }}>
                  <LegendExceedance colors={colors} />
                </View>
              </>
            ) : (
              <EmptyChart colors={colors} />
            )}
          </>

          <View style={pdfStyles.spacer8} />

          <>
            <View wrap={false}>
              <Text style={pdfStyles.label}>RISK NARRATIVE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.risk_narrative ? (
                <Text style={pdfStyles.value}>{scenario.risk_narrative}</Text>
              ) : (
                <NoData colors={colors} />
              )}
            </View>
            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>FREQUENCY</Text>
              <View style={pdfStyles.spacer1} />
              {!(!scenario.frequency_times && !scenario.frequency_years) ? (
                <View style={{ flexDirection: 'column', gap: 4 }}>
                  {scenario.frequency_times && scenario.frequency_years && (
                    <>
                      {scenario.frequency_times && scenario.frequency_years && (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <View
                            style={{
                              width: 7,
                              height: 7,
                              backgroundColor: '#022033',
                              borderRadius: '50%',
                              marginRight: 6,
                            }}
                          />
                          <Text style={pdfStyles.value}>
                            {scenario.frequency_times} time(s) per {scenario.frequency_years} year(s)
                          </Text>
                        </View>
                      )}
                      {scenario.live_frequency_times && scenario.live_frequency_years && (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <View
                            style={{
                              width: 7,
                              height: 7,
                              backgroundColor: '#E62E78',
                              borderRadius: '50%',
                              marginRight: 6,
                            }}
                          />
                          <Text style={pdfStyles.value}>
                            {scenario.live_frequency_times} time(s) per {scenario.live_frequency_years} year(s)
                          </Text>
                        </View>
                      )}
                      {scenario.projected_frequency_times && scenario.projected_frequency_years && (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <View
                            style={{
                              width: 7,
                              height: 7,
                              backgroundColor: '#2BBDEF',
                              borderRadius: '50%',
                              marginRight: 6,
                            }}
                          />
                          <Text style={pdfStyles.value}>
                            {scenario.projected_frequency_times} time(s) per {scenario.projected_frequency_years}{' '}
                            year(s)
                          </Text>
                        </View>
                      )}
                    </>
                  )}
                </View>
              ) : (
                <NoData colors={colors} />
              )}
            </View>

            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>NOTE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.frequency_note ? (
                <Text style={pdfStyles.value}>{scenario.frequency_note}</Text>
              ) : (
                <NoData colors={colors} />
              )}
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <View wrap={false}>
              <Text style={pdfStyles.label}>CONSEQUENCE FACTORS</Text>
              <View style={pdfStyles.spacer8} />
              <View style={{ flexDirection: 'row', gap: 40, marginBottom: 16 }}>
                <View style={{ width: 100 }}>
                  <Text style={pdfStyles.label}>LOWER</Text>
                  <View style={{ marginTop: 8 }}>
                    {scenario.lower != null && <VariantValuesPDF value={{ base: scenario.lower }} variant="scenario" />}
                    {scenario.live_lower != null && (
                      <VariantValuesPDF value={{ live: scenario.live_lower }} variant="scenario" />
                    )}
                    {scenario.projected_lower != null && (
                      <VariantValuesPDF value={{ complete: scenario.projected_lower }} variant="scenario" />
                    )}
                  </View>
                </View>

                <View style={{ width: 100 }}>
                  <Text style={pdfStyles.label}>UPPER</Text>
                  <View style={{ marginTop: 8 }}>
                    {scenario.upper != null && <VariantValuesPDF value={{ base: scenario.upper }} variant="scenario" />}
                    {scenario.live_upper != null && (
                      <VariantValuesPDF value={{ live: scenario.live_upper }} variant="scenario" />
                    )}
                    {scenario.projected_upper != null && (
                      <VariantValuesPDF value={{ complete: scenario.projected_upper }} variant="scenario" />
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>NOTE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.note ? <Text style={pdfStyles.value}>{scenario.note}</Text> : <NoData colors={colors} />}
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <View wrap={false}>
            <Text style={pdfStyles.gradText}>RISK MANAGEMENT</Text>
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.label}>MANAGEMENT STRATEGY</Text>
            <View style={pdfStyles.spacer1} />
            {scenario.management_strategy ? (
              <Text style={pdfStyles.value}>{scenario.management_strategy}</Text>
            ) : (
              <NoData colors={colors} />
            )}
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.label}>NOTE</Text>
            <View style={pdfStyles.spacer1} />
            {scenario.management_note ? (
              <Text style={pdfStyles.value}>{scenario.management_note}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>

          <>
            <View style={pdfStyles.spacer16} />
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.gradText}>RISK MANAGEMENT PLAN FOR THIS SCENARIO</Text>
            <View style={pdfStyles.spacer8} />
            {scenario.controls.length ? (
              <ControlsTable data={scenario.controls} colors={colors} />
            ) : (
              <NoData colors={colors} />
            )}
          </>

          <>
            <View style={pdfStyles.spacer16} />
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.gradText}>INCIDENTS</Text>
            <View style={pdfStyles.spacer8} />
            {scenario.incidents.length ? (
              <IncidentsTable data={scenario.incidents} colors={colors} />
            ) : (
              <NoData colors={colors} />
            )}
          </>
        </View>
        <Footer footerComponent={<FooterStartComponent title={`${footerTitle}${scenario.name}`} />} colors={colors} />
      </Page>
    </>
  );
};
