import React from 'react';
import { Tooltip } from 'src/common';
import styled from 'styled-components';
import { IconCopy, IconPaste } from './comps';
import { Modifiers, Strategy } from 'src/api/types';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';

const Div = styled.div`
  .copy {
    position: absolute;
    top: 15px;
    right: 26px;
    display: flex;
    align-items: center;
    grid-gap: 9px;

    &__btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.white};
      filter: drop-shadow(0px 0px 10px ${({ theme }) => theme.colors.shadowLight});
    }
  }
`;

interface CopyProps {
  modifiers: Modifiers;
  onPaste: (mod: Modifiers) => void;
  strategy: Strategy | null;
  isValid: boolean;
}

export const Copy: React.FC<CopyProps> = ({ modifiers, onPaste, strategy, isValid }) => {
  const copiedModifiers = useStateSelector(({ app }) => app.copiedModifiers);
  const { setCopiedModifiers } = useActionsApp();

  let copiedStrategy: Strategy | null = null;

  if (copiedModifiers?.treat.length) {
    copiedStrategy = Strategy.Treat;
  }
  if (copiedModifiers?.transfer.length) {
    copiedStrategy = Strategy.Transfer;
  }

  const canCopy = (!!modifiers.treat.length || !!modifiers.transfer.length) && isValid;
  const canPaste = !!(copiedModifiers && copiedStrategy === strategy);

  return (
    <Div>
      <div className="copy">
        <Tooltip dataHtml={canCopy ? 'Copy modifiers' : ''} dataId="copy" place="top" positionStrategy="fixed">
          <div
            className="copy__btn"
            onClick={() => canCopy && setCopiedModifiers(modifiers)}
            style={{ cursor: canCopy ? 'pointer' : 'not-allowed' }}
            data-cy="copy-btn"
          >
            <IconCopy disabled={!canCopy} />
          </div>
        </Tooltip>
        <Tooltip dataHtml={canPaste ? 'Paste modifiers' : ''} dataId="paste" place="top" positionStrategy="fixed">
          <div
            className="copy__btn"
            onClick={() => canPaste && onPaste(copiedModifiers)}
            style={{ cursor: canPaste ? 'pointer' : 'not-allowed' }}
            data-cy="paste-btn"
          >
            <IconPaste disabled={!canPaste} />
          </div>
        </Tooltip>
      </div>
    </Div>
  );
};
