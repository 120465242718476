import React from 'react';
import { DataTitle, Poppins } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ModifiersTreat, ProjectedScenario, TreatReduceConsequenceType } from 'src/api/types';
import { SCurrencyInput, Symbol, SymbolSwitch } from '../../../../comps';
import _ from 'lodash';
import { calcModifierReduction } from '../../../util';
import { formatCurrency, getCurrencySymbol } from 'src/utils/misc';

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px 1.6fr 350px;

  .g-fix {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .rc--by {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .rc--close {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.colors.stroke};
    padding-right: 20px;
  }
  .rc--stats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.cflowerBlue};
    }

    &__value {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.prussianBlue} !important;
    }
  }
`;

interface ReduceConsequenceProps {
  projectedScenario: ProjectedScenario;
  idx: number;
  onRemove: () => void;
  disabled?: boolean;
  noResult?: boolean;
}

export const ReduceConsequence: React.FC<ReduceConsequenceProps> = ({ idx, projectedScenario, disabled, noResult }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext<ModifiersTreat<TreatReduceConsequenceType>>();

  const isPercentage = values.treat[idx].data.isPercentage;
  const symbol = isPercentage ? '%' : '#';

  const lowerValue = calcModifierReduction(projectedScenario.lower, values.treat[idx].data.lower || 0, isPercentage);
  const upperValue = calcModifierReduction(projectedScenario.upper, values.treat[idx].data.upper || 0, isPercentage);

  return (
    <Div>
      <div className="slot">
        <div className="slot__top"></div>
        <div className="slot__content rc--by">
          <Poppins color="cflowerBlue">by</Poppins>
          <SymbolSwitch
            symbolA="%"
            symbolB={'#'}
            selectedSymbol={symbol}
            setSymbol={(s) => {
              setFieldValue(`treat[${idx}].data.isPercentage`, s === '%');
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="g-fix">
        <div className="slot">
          <div className="slot__top">
            <DataTitle $noMargin nowrap>
              CONSEQUENCE - LOWER
            </DataTitle>
          </div>
          <div className="slot__content">
            <SCurrencyInput
              id={`treat[${idx}].data.lower`}
              InputProps={{ startAdornment: <Symbol>{symbol}</Symbol> }}
              value={values.treat[idx].data.lower}
              onValueChange={({ floatValue }) => setFieldValue(`treat[${idx}].data.lower`, floatValue)}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="slot">
          <div className="slot__top">
            <DataTitle $noMargin nowrap>
              CONSEQUENCE - UPPER
            </DataTitle>
          </div>
          <div className="slot__content">
            <SCurrencyInput
              id={`treat[${idx}].data.upper`}
              InputProps={{ startAdornment: <Symbol>{symbol}</Symbol> }}
              value={values.treat[idx].data.upper}
              onValueChange={({ floatValue }) => setFieldValue(`treat[${idx}].data.upper`, floatValue)}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      {!noResult && (
        <div className="slot">
          <div className="slot__top"></div>
          <div className="slot__content rc--stats">
            <span>
              Updated Consequence - Lower: <span className="rc--stats__value">{formatCurrency(lowerValue)}</span>
            </span>
            <span>
              Updated Consequence - Upper: <span className="rc--stats__value">{formatCurrency(upperValue)}</span>
            </span>
          </div>
        </div>
      )}
    </Div>
  );
};
