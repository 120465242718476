import React, { useEffect, useRef, useState } from 'react';
import { PageControlSkeleton, Poppins, Spacer } from 'src/common';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { BottomPanel, Slide, Stepper } from '../../comps';
import { Intro } from './comps';
import {
  initialValues as init,
  SLIDE_HEIGHT,
  validationSchema,
  isButtonDisabled,
  nextButtonLabel,
  updateVariablesWithFormData,
} from './util';
import { Guard } from 'src/components/modals';
import { useStateSelector } from 'src/redux';
import { StyledModal } from './styled';
import { motion } from 'framer-motion';
import useVariables from 'src/hooks/useVariables';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { baseWorkspaceSlides, scenarioSlides, standardSlides } from './slides';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { SlideId, WizardFormValues } from 'src/api/types/wizard';
import { queryCache, useMutation } from 'react-query';
import { QKeys } from 'src/api/types';
import { useMixPanelTracking } from './useMixPanelTracking';
import { useAuth } from 'src/state/auth';
import { updateWizard, wizCreateAssessment, wizCreateScenarios } from 'src/api/wizard';

interface IWizzardWorkspaceModalProps {
  onClose: () => void;
  wizardData?: WizardFormValues | null;
  isLoading: boolean;
  isStdVarComplete: boolean;
}

export const WizzardWorkspaceModal: React.FC<IWizzardWorkspaceModalProps> = ({
  onClose,
  wizardData,
  isLoading,
  isStdVarComplete,
}) => {
  const wizardWorkspace = useStateSelector(({ app }) => app.wizardWorkspace);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [step, setStep] = useState(0);
  const [initialValues, setInitialValues] = useState<WizardFormValues>(init);
  const { variables } = useVariables({});
  const [slides, setSlides] = useState<typeof standardSlides>([]);
  const [canRender, setCanRender] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const { activeWorkspace } = useAuth();

  const [updateWizardData] = useMutation(updateWizard);
  const [createAssessment] = useMutation(wizCreateAssessment, {
    onSuccess: () => {
      queryCache.invalidateQueries([QKeys.Assessments]);
      onClose();
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const isLastSlide = step === slides.length - 1;
  const isInitialRender = useRef(true);

  const [sliderRef, slider] = useKeenSlider({
    slideChanged: (slider) => {
      setStep(slider.track.details.abs);
    },
    drag: false,
  });

  const onChangeSlide = (id: SlideId) => {
    const idx = slides.findIndex((el) => el.id === id);
    slider.current?.moveToIdx(idx, true, { duration: 0 });
  };

  useEffect(() => {
    setError(null);
    validateForm();
    if (!isCloseRequested && isInitialRender.current) {
      setTimeout(() => {
        slider.current?.moveToIdx(step, true, { duration: 0 });
        isInitialRender.current = false;
      }, 0);
    }
  }, [step, isCloseRequested]);

  useEffect(() => {
    if (!isLoading) {
      const initData = _.cloneDeep(wizardData) || init;

      // updateInitVariables(initData, standardVariableValues); //keep this

      if (wizardWorkspace?.intent.type === WizardIntent.workspace) {
        const createAssessment = wizardWorkspace.intent.params.createAssessment;
        if (createAssessment) {
          setSlides(standardSlides);
        } else {
          setSlides(baseWorkspaceSlides);
        }
        setTimeout(() => {
          onChangeSlide(
            isStdVarComplete
              ? createAssessment
                ? SlideId.assessmentDetails
                : SlideId.organisation
              : SlideId.organisation,
          );
        }, 0);
      }

      if (wizardWorkspace?.intent.type === WizardIntent.assessment) {
        setSlides(standardSlides);
        setTimeout(() => {
          onChangeSlide(
            isStdVarComplete
              ? isStdVarComplete
                ? SlideId.assessmentDetails
                : SlideId.organisation
              : SlideId.organisation,
          );
        }, 0);
      }

      if (wizardWorkspace?.intent.type === WizardIntent.scenario) {
        initData.assessment = wizardWorkspace.intent.params.assessmentData;
        setSlides(scenarioSlides);
        onChangeSlide(SlideId.scenarios);
      }

      setInitialValues(initData);
      setCanRender(true);
      setTimeout(() => {
        isInitialRender.current = false;
      }, 0);
    }
  }, [isLoading, wizardWorkspace, showIntro]);

  const requireAssessment =
    wizardWorkspace?.intent.type === WizardIntent.assessment ||
    (wizardWorkspace?.intent.type === WizardIntent.workspace && wizardWorkspace.intent.params.createAssessment);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(requireAssessment),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (val) => {
      setError(null);

      try {
        if (wizardWorkspace?.intent.type === WizardIntent.workspace) {
          mpEvent(MPEvents.WizardSubmit, {
            wizardType: 'workspace',
          });

          if (wizardWorkspace.intent.params.createAssessment) {
            resetTempValues();
            return createAssessment(val);
          } else {
            onClose();
          }
        }

        if (wizardWorkspace?.intent.type === WizardIntent.assessment) {
          mpEvent(MPEvents.WizardSubmit, {
            wizardType: 'assessment',
          });
          resetTempValues();
          return createAssessment(val);
        }

        if (wizardWorkspace?.intent.type === WizardIntent.scenario) {
          mpEvent(MPEvents.WizardSubmit, {
            wizardType: 'scenario',
          });
          resetTempValues();

          const assessmentId = wizardWorkspace.intent.params.assessmentData.id;
          return wizCreateScenarios({ data: val, id: assessmentId })
            .then(() => {
              queryCache.invalidateQueries([QKeys.Assessment, { assessmentId }]);
              onClose();
            })
            .catch((error: Error) => {
              setError(error);
            });
        }
      } catch (error: any) {
        setError(error);
      }
    },
  });

  const { dirty, isSubmitting, handleSubmit, values, errors, setErrors, resetForm, isValid, validateForm } = formik;

  useMixPanelTracking({
    canRender,
    slides,
    step,
    isInitialRender,
  });

  const saveWizardProgress = async (params?: { close?: boolean }) => {
    const close = params?.close;

    if (dirty) {
      resetForm({
        values,
      });

      await updateWizardData({
        data: values,
        id: activeWorkspace?.id as string,
      });
    }
    if (close) {
      setIsCloseRequested(true);
    }
  };

  const resetTempValues = async () => {
    await updateWizardData({
      data: { ...values, assessment: init.assessment, scenarios: init.scenarios },
      id: activeWorkspace?.id as string,
    });
  };

  const saveVariables = async () => {
    await updateVariablesWithFormData(formik.values, variables);
  };

  if (!canRender) return null;

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal
          onRequestClose={() => {
            setIsCloseRequested(true);
            isInitialRender.current = true;
          }}
          isOpen
          isWizzard
        >
          <Spacer $px={10} />
          {showIntro ? (
            <Intro onClose={onClose} onProceed={() => setShowIntro(false)} />
          ) : (
            <>
              {!isLoading ? (
                <FormikProvider value={formik}>
                  <motion.div
                    className="wm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.1, delay: 0.3 }}
                  >
                    <div className="wm__top h-padding">
                      <Poppins className="m-title" px={28}>
                        {slides[step]?.title}
                      </Poppins>
                      <Spacer $px={20} />
                      <Stepper
                        slides={slides}
                        step={step}
                        onChangeSlide={(slideId) => {
                          onChangeSlide(slideId);
                          saveVariables();
                          saveWizardProgress();
                          mpEvent(MPEvents.WizardSlideChanged, {
                            slideId,
                          });
                        }}
                      />
                      <Spacer $px={40} />
                    </div>
                    <div className="wm__content" ref={sliderRef}>
                      {slides.map((el, idx) => {
                        const Cmp = el.component;
                        return (
                          <Slide isCurrent={step === idx} slideHeight={SLIDE_HEIGHT}>
                            <Cmp
                              onChangeSlide={(slideId) => {
                                mpEvent(MPEvents.WizardSlideChanged, {
                                  slideId,
                                });
                                onChangeSlide(slideId);
                              }}
                            />
                          </Slide>
                        );
                      })}
                    </div>
                  </motion.div>
                  <div className="wm__bottom h-padding">
                    <BottomPanel
                      onSave={async () => {
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Save',
                          tags: ['WIZARD'],
                          modal: 'Wizzard modal',
                        });
                        await saveVariables();
                        await saveWizardProgress({ close: true });
                      }}
                      btnPrev={
                        step !== 0 && {
                          exe: () => {
                            const slideId = slides[step - 1]?.id;
                            if (slideId) {
                              mpEvent(MPEvents.WizardSlideChanged, {
                                slideId,
                              });
                            }

                            saveVariables();
                            saveWizardProgress();
                            slider.current?.prev();
                          },
                          icon: true,
                          label: 'PREVIOUS',
                        }
                      }
                      btnNext={{
                        exe: () => {
                          const slideId = slides[step + 1]?.id;
                          if (slideId) {
                            mpEvent(MPEvents.WizardSlideChanged, {
                              slideId,
                            });
                          }

                          saveVariables();
                          saveWizardProgress();
                          slider.current?.next();
                          if (isLastSlide) {
                            handleSubmit();
                          }
                        },
                        icon: !isLastSlide,
                        label: nextButtonLabel({
                          isLastSlide,
                          wizardWorkspace,
                          isStdVarComplete,
                        }),
                        disabled:
                          isButtonDisabled({
                            isValid,
                            errors,
                            isLastSlide,
                            wizardWorkspace,
                          }) || isSubmitting,
                      }}
                    />
                  </div>

                  {error && (
                    <div className="err-container">
                      <Spacer $px={10} />
                      <Poppins px={14} color="error">
                        {error.message || 'Server error'}
                      </Poppins>
                    </div>
                  )}
                </FormikProvider>
              ) : (
                <div className="h-padding">
                  <PageControlSkeleton />
                </div>
              )}
            </>
          )}
          <Spacer $px={40} />
        </StyledModal>
      }
    />
  );
};
