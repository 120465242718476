import React from 'react';
import { Link } from 'react-router-dom';
import { encodeQueryParams } from 'src/api/client';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';

const SLink = styled(Link)`
  cursor: pointer;
  color: inherit;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gp1};
    * {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gp1};
    }
  }
`;

interface TLLinkProps {
  children: React.ReactNode;
  date: string;
  newPage?: boolean;
}

export const TLLink: React.FC<TLLinkProps> = ({ children, date, newPage }) => {
  return (
    <SLink
      to={`/timeline${encodeQueryParams({ date })}`}
      target={newPage ? '_blank' : undefined}
      onClick={() =>
        mpEvent(MPEvents.DateToTimelineLink, {
          date,
        })
      }
    >
      {children}
    </SLink>
  );
};
