import { Assessment, Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { formatDate, sanitizeForCSV } from 'src/utils/misc';
import _ from 'lodash';
import { ScenarioCsvHeaders } from 'src/api/types/misc';

export const getCSVData = (scenarios: { base: Scenario[]; live: Scenario[]; projected: Scenario[] }) => {
  return [
    [
      ScenarioCsvHeaders.Name,
      ScenarioCsvHeaders.ID,
      ScenarioCsvHeaders.Sources,
      ScenarioCsvHeaders.Events,
      ScenarioCsvHeaders.Consequences,
      ScenarioCsvHeaders.RiskOwner,
      ScenarioCsvHeaders.RiskNarrative,
      'Unmodified Expected Loss',
      'Live Expected Loss',
      'Projected Expected Loss',
      ScenarioCsvHeaders.UnmodifiedFrequencyTimes,
      ScenarioCsvHeaders.UnmodifiedFrequencyYears,
      ScenarioCsvHeaders.LiveFrequencyTimes,
      ScenarioCsvHeaders.LiveFrequencyYears,
      ScenarioCsvHeaders.ProjectedFrequencyTimes,
      ScenarioCsvHeaders.ProjectedFrequencyYears,
      ScenarioCsvHeaders.FrequencyNote,
      ScenarioCsvHeaders.UnmodifiedConsequenceLower,
      ScenarioCsvHeaders.UnmodifiedConsequenceUpper,
      ScenarioCsvHeaders.LiveConsequenceLower,
      ScenarioCsvHeaders.LiveConsequenceUpper,
      ScenarioCsvHeaders.ProjectedConsequenceLower,
      ScenarioCsvHeaders.ProjectedConsequenceUpper,
      ScenarioCsvHeaders.ConsequenceNote,
      'Created (DD-MM-YYYY, HH:mm)',
      'Updated (DD-MM-YYYY, HH:mm)',
      ScenarioCsvHeaders.IdentifiedDate,
      ScenarioCsvHeaders.ReviewDate,
      ScenarioCsvHeaders.RiskManagementStrategy,
      ScenarioCsvHeaders.RiskManagementNote,
    ],
    ...scenarios.base.map((el) => {
      const liveItem = _.find(scenarios.live, { id: el.id });
      const projectedItem = _.find(scenarios.projected, { id: el.id });
      return [
        sanitizeForCSV(el.name), // Name
        sanitizeForCSV(el.id), // ID
        sanitizeForCSV(riskSourcesNames[el.source]), // Sources
        sanitizeForCSV(riskEventsNames[el.event]), // Events
        sanitizeForCSV(riskConsequencesNames[el.consequence]), // Consequences
        sanitizeForCSV(el.risk_owner?.text), // Risk Owner
        sanitizeForCSV(el.risk_narrative), // Risk Narrative
        Math.round(el.expected_loss), // Unmodified Expected Loss
        liveItem ? Math.round(liveItem.expected_loss) : '', // Live Expected Loss
        projectedItem ? Math.round(projectedItem.expected_loss) : '', // Projected Expected Loss
        sanitizeForCSV(el.frequency_times), // Unmodified Frequency Times
        sanitizeForCSV(el.frequency_years), // Unmodified Frequency Years
        sanitizeForCSV(liveItem?.frequency_times), // Live Frequency Times
        sanitizeForCSV(liveItem?.frequency_years), // Live Frequency Years
        sanitizeForCSV(projectedItem?.frequency_times), // Projected Frequency Times
        sanitizeForCSV(projectedItem?.frequency_years), // Projected Frequency Years
        sanitizeForCSV(el.frequency_note), // Frequency Note
        Math.round(el.lower), // Unmodified Consequence Lower
        Math.round(el.upper), // Unmodified Consequence Upper
        liveItem ? Math.round(liveItem?.lower) : '', // Live Consequence Lower
        liveItem ? Math.round(liveItem.upper) : '', // Live Consequence Upper
        projectedItem ? Math.round(projectedItem.lower) : '', // Projected Consequence Lower
        projectedItem ? Math.round(projectedItem.upper) : '', // Projected Consequence Upper
        sanitizeForCSV(el.note), // Consequence Note
        formatDate(el.created_at, { formatType: 'datetime' }), // Created
        formatDate(el.updated_at, { formatType: 'datetime' }), // Updated
        formatDate(el.risk_identified_date), // Identified Date
        formatDate(el.review_date), // Review Date
        sanitizeForCSV(el.management_strategy), // Risk Management Strategy
        sanitizeForCSV(el.management_note), // Risk Management Note
      ];
    }),
  ];
};

export interface VariantAssessment extends Omit<Assessment, 'scenarios'> {
  scenarios: VariantsScenario[];
}

export interface VariantsScenario extends Omit<Scenario, 'expected_loss'> {
  expected_loss: {
    base: number;
    live: number;
    projected: number;
  };
}

type MergeScenarios = (params: {
  liveData: Scenario[];
  baseData: Scenario[];
  completeData: Scenario[];
}) => VariantsScenario[];

export const mergeScenarios: MergeScenarios = ({ liveData, baseData, completeData }) => {
  return liveData.map((el) => {
    const baseItem = _.find(baseData, { id: el.id });
    const projectedItem = _.find(completeData, { id: el.id });

    return {
      ...el,
      expected_loss: {
        base: baseItem?.expected_loss || el.expected_loss,
        live: el.expected_loss,
        projected: projectedItem?.expected_loss || el.expected_loss,
      },
    };
  });
};
