import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderData } from 'src/redux/types';
import { WizzardWorkspaceType } from './types';
import { Modifiers } from 'src/api/types';

interface LocationPath {
  name: string;
  id: string;
}

interface State {
  isMaintenanceMode: boolean;
  location: {
    header: HeaderData[];
    paths: {
      assessment?: LocationPath;
      scenario?: LocationPath;
      rmpControl?: LocationPath;
      incident?: LocationPath;
      framework?: LocationPath;
      frameworkControl?: LocationPath;
      scenarioTemplate?: LocationPath;
    };
  };
  wizardWorkspace: null | WizzardWorkspaceType;
  copiedModifiers: Modifiers | null;
}

type LocationNames = keyof State['location']['paths'];

const initialState: State = {
  isMaintenanceMode: false,
  location: { header: [], paths: {} },
  wizardWorkspace: null,
  copiedModifiers: null,
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMaintenanceMode: (state, action: PayloadAction<boolean>) => {
      state.isMaintenanceMode = action.payload;
    },
    setLocationHeader: (state, action: PayloadAction<HeaderData[]>) => {
      state.location.header = action.payload;
    },
    setLocationPaths: (state, action: PayloadAction<Partial<{ [key in LocationNames]: LocationPath }>>) => {
      state.location.paths = { ...state.location.paths, ...action.payload };
    },
    setWizardWorkspace: (state, action: PayloadAction<null | WizzardWorkspaceType>) => {
      state.wizardWorkspace = action.payload;
    },
    setCopiedModifiers: (state, action: PayloadAction<Modifiers | null>) => {
      state.copiedModifiers = action.payload;
    },
  },
});

export default slice.reducer;
