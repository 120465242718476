import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import CommonMenu from 'src/components/CommonMenu';
import { deleteAssessment } from 'src/api/assessment';
import { useNavigate } from 'react-router';
import { useAssessment } from 'src/state/assessment';
import { queryCache, useMutation } from 'react-query';
import { isBefore } from 'date-fns';
import { Poppins, DateCapsule, TextOverflowTooltip } from 'src/common';
import { Assessment, QKeys } from 'src/api/types';
import { ConfirmDeleteModal } from '../../../components/modals';
import { ThreeDots } from 'src/components/CommonMenu/comps';
import { IncompleteStateMarker } from 'src/common/IncompleteStateMarker';
import { Tile, TileDescription, TileDescriptionContainer, TileTitle } from 'src/components/Tile';
import { Link } from 'react-router-dom';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { ScenarioSvg } from 'src/assets/dynamicSvg';

const Container = styled(Tile)<{ $isSelected: boolean; $disabled?: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.gp2};
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `};
`;

const BottomContainer = styled.div`
  display: flex;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

interface AssessmentCardProps {
  assessment: Assessment;
  isSelected: boolean;
  compareMode: boolean;
  onSelect: (val: string) => void;
  disabled?: boolean;
}

export const AssessmentCard: React.FC<AssessmentCardProps> = ({
  assessment,
  isSelected,
  compareMode,
  onSelect,
  disabled,
}) => {
  const navigate = useNavigate();
  const { openModal } = useAssessment();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [remove] = useMutation(deleteAssessment, {
    onSuccess: () => {
      mpEvent(MPEvents.AssessmentDelete, { id: assessment.id, tags: ['ASSESSMENT'] });
      queryCache.invalidateQueries(QKeys.Assessments).then(() => {
        handleClose();
      });
    },
  });

  const handleOnEditClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    handleClose();
    openModal(assessment.id);
  };
  const handleOnViewClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(`/risk-assessments/${assessment.id}`);
  };

  const handleOnDeleteClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    remove({ id: assessment.id }).finally(() => {
      setIsDeleteModalOpen(false);
    });
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    handleClose();
    setIsDeleteModalOpen(true);
  };

  const hasIncompleteTech = assessment.scenarios.some((s) => s.is_incomplete === 1 || s.is_incomplete === 3);

  return (
    <Container
      className="hover-shadow"
      $isSelected={isSelected}
      $disabled={disabled}
      onClick={() => {
        if (compareMode) {
          onSelect(assessment.id);
        } else {
          if (!anchorEl) {
            navigate(`/risk-assessments/${assessment.id}`);
            mpEvent(MPEvents.Link, {
              source: { value: ['Assessments'] },
              destination: { value: ['Assessment'], params: { id: assessment.id } },
              tags: ['ASSESSMENT'],
            });
          }
        }
      }}
    >
      <CommonMenu
        onEditClick={handleOnEditClick}
        onViewClick={handleOnViewClick}
        onDeleteClick={openDeleteModal}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={handleOnDeleteClick} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      <div css="display: flex;justify-content: space-between;align-items: space-between;">
        <TextOverflowTooltip tooltipText={assessment.name}>
          {compareMode ? (
            <TileTitle data-cy="assessment-card-title">{assessment.name}</TileTitle>
          ) : (
            <Link
              to={`/risk-assessments/${assessment.id}`}
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: { value: ['Assessments'] },
                  destination: { value: ['Assessment'], params: { id: assessment.id } },
                  tags: ['ASSESSMENT'],
                });
              }}
            >
              <TileTitle data-cy="assessment-card-title">{assessment.name}</TileTitle>
            </Link>
          )}
        </TextOverflowTooltip>
        <div css="display: flex;align-items: center;">
          {hasIncompleteTech && (
            <div css="margin-right: 8px;margin-left: autopx;">
              <IncompleteStateMarker
                initState="TECH"
                titleTech="One or more scenarios are missing calculation data and have been excluded from the calculations."
              />
            </div>
          )}
          {!compareMode && (
            <ThreeDots aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} dataCy="tdm-card" />
          )}
        </div>
      </div>
      <div />

      <TileDescriptionContainer>
        <TileDescription>
          <Poppins px={14} color="cflowerBlue">
            {assessment.description}
          </Poppins>
        </TileDescription>
        <Poppins px={22} weight={600} nowrap>
          {numeralFormat(assessment.expected_loss)}
        </Poppins>
      </TileDescriptionContainer>
      <BottomContainer>
        <div css="display: flex;align-items: center;flex: 1;">
          <ScenarioSvg />
          <div>
            <Poppins css="margin-left: 6px;" px={18} weight={500}>
              {assessment.scenarios_count}
            </Poppins>
            <Poppins css="margin-left: 4px;" px={14} color="cflowerBlue">
              {assessment.scenarios_count === 1 ? 'scenario' : 'scenarios'}
            </Poppins>
          </div>
        </div>
        <DateContainer>
          <Poppins weight={500} px={14} color="cflowerBlue" nowrap css="margin-right: 10px">
            Review Date
          </Poppins>
          <DateCapsule $highlighted={isBefore(new Date(assessment.review_date), new Date())}>
            <Poppins weight={600} px={14}>
              {formatDate(assessment.review_date)}
            </Poppins>
          </DateCapsule>
        </DateContainer>
      </BottomContainer>
    </Container>
  );
};
