import React from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { isUndefined, numeralFormat, getCurrencySymbol } from 'src/utils/misc';
import { pdfStyles } from '../pdfStyles';
import { NoData } from './NoData';
import { getExternalPath } from '../util';
import { extLinkImg } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface ScenariosTableProps {
  data: Scenario[];
  colors: ColorSet;
  currentControlId?: string;
}

export const ScenariosTable: React.FC<ScenariosTableProps> = ({ data, colors, currentControlId }) => {
  const symbol = getCurrencySymbol();

  return (
    <View>
      <Text style={pdfStyles.label}>RISK SCENARIOS</Text>
      <View style={pdfStyles.spacer4} />
      {data.length ? (
        <>
          <View
            wrap={false}
            break={false}
            style={{
              height: 24,
              border: `1px solid ${colors.cflowerBlue}`,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View style={{ ...pdfStyles.tableCell, flex: 1.5 }}>
              <Text style={pdfStyles.tableHeader}>Name</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
              <Text style={pdfStyles.tableHeader}>Risk Owner</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
              <Text style={pdfStyles.tableHeader}>Sources</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
              <Text style={pdfStyles.tableHeader}>Events</Text>
            </View>
            <View style={pdfStyles.tableCell}>
              <Text style={pdfStyles.tableHeader}>Consequences</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.9 }}>
              <Text style={pdfStyles.tableHeader}>Expected Loss</Text>
            </View>
          </View>
          {data.map((el, idx) => (
            <React.Fragment key={`scenario-${el.id}`}>
              <View
                wrap={false}
                break={false}
                style={{
                  minHeight: 24,
                  border: `1px solid ${colors.cflowerBlue}`,
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: -1,
                }}
              >
                <View style={{ ...pdfStyles.tableCell, flex: 1.5 }}>
                  <Link
                    src={getExternalPath(`risk-assessments/${el.assessment_id}/scenario/${el.id}`)}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={{ ...pdfStyles.tableValue, textDecoration: 'underline', marginRight: 6 }}>
                      {el.name || '-'}
                    </Text>
                    <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
                  </Link>
                </View>
                <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
                  <Text style={pdfStyles.tableValue}>{el.risk_owner?.text || '-'}</Text>
                </View>
                <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
                  <Text style={pdfStyles.tableValue}>
                    {!isUndefined(el.source) ? riskSourcesNames[el.source] : '-'}
                  </Text>
                </View>
                <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
                  <Text style={pdfStyles.tableValue}>{!isUndefined(el.event) ? riskEventsNames[el.event] : '-'}</Text>
                </View>
                <View style={pdfStyles.tableCell}>
                  <Text style={pdfStyles.tableValue}>
                    {!isUndefined(el.consequence) ? riskConsequencesNames[el.consequence] : '-'}
                  </Text>
                </View>
                <View style={{ ...pdfStyles.tableCell, flex: 0.9 }}>
                  <Text style={{ ...pdfStyles.tableValue, fontWeight: 600, fontSize: 10 }}>
                    {el.expected_loss ? `${symbol}${numeralFormat(el.expected_loss)}` : '-'}
                  </Text>
                </View>
              </View>

              {(() => {
                const allModifiers = [
                  // @ts-ignore
                  ...(el.modifiers || []),
                  ...(
                    (el as any).controls?.filter(
                      (control: { id: string; modifiers?: { data: string | object; type: string }[] }) =>
                        control.id === currentControlId,
                    )[0]?.modifiers || []
                  ).map((modifier: { data: string | object; type: string }) => ({
                    ...modifier,
                    data: typeof modifier.data === 'string' ? JSON.parse(modifier.data) : modifier.data,
                  })),
                ];

                if (allModifiers.length === 0) return null;

                const sortedModifiers = allModifiers.slice().sort((a, b) => {
                  if (a.type === 'NOTE_TYPE') return 1;
                  if (b.type === 'NOTE_TYPE') return -1;
                  return a.type.localeCompare(b.type);
                });

                return sortedModifiers.map((modifier, modifierIdx) => {
                  const data = modifier.data;
                  let modifierText = '';

                  switch (modifier.type) {
                    case 'TREAT_REDUCE_CONSEQUENCE':
                      modifierText = `Reduces consequence by ${
                        data.isPercentage ? `${data.lower}%` : `${symbol}${data.lower}`
                      } (Lower) and ${data.isPercentage ? `${data.upper}%` : `${symbol}${data.upper}`} (Upper)`;
                      break;
                    case 'TREAT_REDUCE_FREQUENCY':
                      modifierText = `Reduces frequency by ${data.isPercentage ? `${data.value}%` : `#${data.value}`}`;
                      break;
                    case 'NOTE_TYPE':
                      modifierText = `Note: ${data.note}`;
                      break;
                    case 'TRANSFER_LIMIT':
                      modifierText = `Reduces risk with a total policy cover of ${symbol}${data.tpc.toLocaleString()} and an excess of ${symbol}${data.excess.toLocaleString()}`;
                      break;
                    default:
                      modifierText = 'Unknown modifier type';
                  }

                  return (
                    <View
                      key={`modifier-${modifierIdx}`}
                      style={{
                        backgroundColor: colors.skeletonHighlight,
                        padding: 6,
                        borderLeft: `1px solid ${colors.cflowerBlue}`,
                        borderRight: `1px solid ${colors.cflowerBlue}`,
                        borderBottom:
                          modifierIdx === sortedModifiers.length - 1 ? `1px solid ${colors.cflowerBlue}` : 'none',
                      }}
                    >
                      <Text style={pdfStyles.tableValue}>{modifierText}</Text>
                    </View>
                  );
                });
              })()}
            </React.Fragment>
          ))}
        </>
      ) : (
        <NoData colors={colors} />
      )}
    </View>
  );
};
