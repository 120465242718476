import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Control, PlanScenarios } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander } from 'src/components/GenericTable';
import { formatDate, formatNumber } from 'src/utils/misc';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { OpenInNew } from '@mui/icons-material';
import { TLLink } from 'src/pages/DashboardPage/comps';
import { TextOverflowTooltip } from 'src/common';

type UseColumns = (a: { data?: Control[]; linkedControls: PlanScenarios[]; scenarioId: string }) => {
  columns: ColumnDef<Control>[];
  GTColumnSelectAnchorExported: JSX.Element;
  checked: string[];
  resetChecked: () => void;
};

const defaultColumns = ['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status', 'updated_at'];

export const useColumns: UseColumns = ({ data = [], linkedControls, scenarioId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.scenarioLinkExistingControl}-columns`,
    defaultColumns,
  );
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<Control>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler, visibleRows } = gTCheckHandler(
            {
              checked,
              selector: 'id',
              table,
            },
            setChecked,
          );
          const isAllLinked = visibleRows.every((el) => linkedControls.some((linked) => linked.control_data.id === el));

          return (
            <GTCheckBox isChecked={isAllChecked || isAllLinked} onClick={checkAllHandler} disabled={isAllLinked} />
          );
        },
        cell: ({ row }) => {
          const id = row.original.id;
          const isLinked = linkedControls.some((el) => el.control_data.id === id);
          return (
            <GTCheckBox
              isChecked={checked.includes(id) || isLinked}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
              disabled={isLinked}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'strategy',
        header: 'Strategy',
        accessorFn: (row) => row.strategy || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <TextOverflowTooltip>
            <Link
              className="table-link"
              to={`/risk-management/control/${info.row.original.id}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: { value: ['Scenario', 'Modal:LinkControlsToScenario'], params: { id: scenarioId } },
                  destination: { value: ['Control'], params: { id: info.row.original.id } },
                  tags: ['RMP', 'SCENARIO'],
                });
              }}
            >
              {info.getValue() as string}
            </Link>
          </TextOverflowTooltip>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorFn: (row) => row.owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'frameworkLibrary_shortname',
        header: 'Framework',
        accessorFn: (row) => row.frameworkLibrary_shortname || null,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/control-frameworks/${info.row.original.frameworkLibrary_ux_id}`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              mpEvent(MPEvents.Link, {
                source: { value: ['Scenario', 'Modal:LinkControlsToScenario'], params: { id: info.row.original.id } },
                destination: { value: ['Framework'] },
                tags: ['RMP', 'SCENARIO'],
              });
            }}
          >
            {info.getValue() as string}
          </Link>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlId',
        header: 'Framework ID',
        accessorFn: (row) => row.controlId || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'annual_cost',
        header: 'Annual Cost',
        accessorFn: (row) => row.annual_cost || null,
        cell: ({ row }) => formatNumber(row.original.annual_cost, { symbol: true }),
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.status || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Edited',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => {
          const date = row.original.updated_at;
          if (!date) return null;
          return (
            <TLLink date={date} newPage>
              {formatDate(date, { formatType: 'datetime' })}
            </TLLink>
          );
        },
      },

      {
        id: 'source',
        header: 'Link',
        accessorFn: (row) => row.source || null,
        cell: (info) => {
          const url = info.getValue();
          const linkText = (info.row.original.url_name || url) as string;
          return url ? (
            <a
              href={url as string}
              target="_blank"
              rel="noopener noreferrer"
              className="table-link"
              onClick={(e) => e.stopPropagation()}
            >
              {linkText}{' '}
              <OpenInNew
                css={`
                  font-size: 14px !important;
                  color: inherit;
                `}
              />
            </a>
          ) : (
            <span>{linkText}</span>
          );
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked, linkedControls]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'strategy', title: 'Strategy' },
        { key: 'name', title: 'Name' },
        { key: 'owner', title: 'Owner' },
        { key: 'frameworkLibrary_shortname', title: 'Framework' },
        { key: 'controlId', title: 'Framework ID' },
        { key: 'annual_cost', title: 'Annual Cost' },
        { key: 'status', title: 'Status' },
        { key: 'updated_at', title: 'Last Edited' },
        { key: 'source', title: 'Link' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    checked,
    resetChecked: () => setChecked([]),
  };
};
